'use client'

import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { CallbackModal, CallbackSuccessModal } from '@/components/modal'
import React, { useState } from 'react'

const MainWorkForYou = () => {
	const t = useTranslations("pages.main.work-for-you");

	const [isCallbackModalShown, setIsCallbackModalShown] = useState(false)
	const [isCallbackSuccessModalShown, setIsCallbackSuccessModalShown] = useState(false)

	const handleOpenCallbackSuccessModalAction = () => {
		setIsCallbackModalShown(false)
		setIsCallbackSuccessModalShown(true)
	}

	return (
		<section className="we-work-for-your">
			<div className="top-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 box-title">
							<h2>
								{
									t.rich("title", {
										br: () => <br/>,
										span: (chunks) => <span>{chunks}</span>,
									})
								}
							</h2>
						</div>
						<div className="col-12">
							<div className="description">
								{t('sub-title')}
							</div>
							<div className="order-call">
								<a type="button" onClick={() => setIsCallbackModalShown(true)}>
									{t('submit')}
								</a>
							</div>
							<div className="logo-box">
								<Image src="/static/images/logo-mini.svg" alt="logo" fill />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-6 left-side">
							<p>{t('history')}</p>
						</div>
						<div className="col-lg-6 right-side">
							<p>{t('equipment-capabilities')}</p>
							<p>{t('our-philosophy')}</p>
						</div>
					</div>
				</div>
			</div>

			{isCallbackModalShown && (
				<CallbackModal
					complete={handleOpenCallbackSuccessModalAction}
					close={() => setIsCallbackModalShown(false)}
				/>
			)}

			{isCallbackSuccessModalShown && (
				<CallbackSuccessModal
					close={() => setIsCallbackSuccessModalShown(false)}
				/>
			)}
		</section>
	)
}

export default MainWorkForYou