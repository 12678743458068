import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.1_next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_a6qq5yw7ge2wygdcyoedpappwi/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.83.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.83.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainCatalogSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainProductList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainPromoVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainVideoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainVideoList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/main/MainWorkForYou.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/simple/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/smart/CatalogCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/smart/ContactForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/smart/ContactInformation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/smart/DealersOnMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/smart/ProductCard.tsx");
